<template>
    <div>
        <card-header title="New Hotel" icon="fa-plus"/>

        <card-body>
            <hotel-form :hotel="newHotel"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newHotel.title" @click="submit"><i class="fas fa-plus mr-3"></i>New Hotel</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '@/TIER/components/CardHeader';
    import CardBody from "@/TIER/components/CardBody";
    import CardFooter from "@/TIER/components/CardFooter";

    import HotelForm from "./HotelForm";

    import {client as http} from '@/http_client';

    export default {
        props: ['card', 'props'],
        components: {CardBody, CardFooter, CardHeader, HotelForm},
        data: function () {
            return {
                newHotel: {},
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/trips/hotels/', {trip_id: this.props.tripId, ...this.newHotel}, {force: true}).then(response => {
                    this.$reloadCard('trip-hotels', {tripId: this.props.tripId});
                    this.$reloadCard('trip', {dayId: this.props.tripId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new hotel? Unsaved changes will be lost.');
        }
    };
</script>
